<template>
    <section class="content">
        <table class="table table-hover" ref="tableuser">
            <thead>
                <tr>
                    <th>KELAS</th>
                    <th>HARI</th>
                    <th>TANGGAL</th>
                    <th>PUKUL</th>
                    <th>MATERI</th>
                    <th>UJIAN / LATIHAN</th>
                    <th>KETERANGAN</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
    </section>
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import moment from "moment";

export default {
    name: 'Lessonplan',
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            groups: [],
            optMapel: [],
            optKelas: [],
            optTentor: [],
            optBranch: [],
            optProgram: [],
            optMateri: [],
            optUjian: [],
            showMateri: [],
            formTitle: 'Tambah',
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
    },
    methods: {
        handleClick(e) {
            if (e.target.matches(".materi")) {
				this.$router.push({ path: "/materi-ajar-content-detail/" + e.target.dataset.to });
			}
            if (e.target.matches(".ujian")) {
				this.$router.push("/ujian-finish-hasil/" + e.target.dataset.kode + "/" + e.target.dataset.mapelid + "/" + e.target.dataset.type);
			}
        },
    },
    mounted() {
        const e = this.$refs;
        var self = this;

        this.table = createTable(e.tableuser, {
            title: "",
            roles: ["read"],
            ajax: "/tentor/lesson_plan",
            order: [[ 2, "asc" ]],
            scrollX: true,
            columnDefs: [{
                "defaultContent": "-",
                "targets": "_all"
            }],
            columns: [
                { data: "kelas" },
                { data: "hari", sortable: false,
                render: function (data, type, row, meta) {
                    var namahari = ("Minggu Senin Selasa Rabu Kamis Jumat Sabtu");
                    namahari = namahari.split(" ");
                    var tgl = new Date(row.date);
                    var hari = tgl.getDay();
                    return namahari[hari];
                } },
                { data: "date",
                render: function (data, type, row, meta) {
                    return moment(data).format("DD/MM/YYYY");
                } },
                { data: "time_start",
                render: function (data, type, row, meta) {
                    var time_start = data ? data.substring(0,5) : '';
                    var time_end = row.time_end ? row.time_end.substring(0,5) : '';
                    return time_start+' - '+time_end;
                } },
                { data: "materi",
                render: function (data, type, row, meta) {
                    if (!data) {
                        return '-';
                    } else {
                        return '<a class="text-info materi" style="cursor:pointer;" data-to="' +
                        row.materi_id + '">' + data + "</a>";
                    }
                } },
                { data: "ujian_title",
                render: function (data,type,row,meta) {
                    if (!data) {
                        return '-';
                    } else {
                    return '<a class="text-info ujian" style="cursor:pointer;" data-kode="' +
                    row.ujian_code + '" data-mapelid="' + row.mapel_id + '" data-type="' + row.ujian_type + '">' + data + "</a>";
                    }
                } },
                { data: "keterangan",
                render: function (data, type, row, meta) {
                    return data && data != 'null' ? data : '';
                } },
            ],
        });
    },
}
</script>
